<template>
  <div class="mx-auto relative ml-2.5" v-if="data">
    <Navbar class="px-10" />
    <div id="tools-catalog" class="mx-10 py-4">
      <div class="text-black text-3xl font-bold ml-1">Tools</div>
      <div class="text-black ml-1">
        Use this feature to recognize accomplishments and wish employees on
        significant important days. Make their day fun and memorable.
      </div>
      <div class="flex gap-4 mt-4" v-if="data.experiences">
        <div
          v-for="item in data.experiences"
          :key="item.id"
          class="w-1/4 border rounded-xl p-2 text-black"
        >
          <router-link
            :to="{
              name: 'ToolsExperienceForm',
              params: { experience_id: item.id },
            }"
          >
            <div class="flex justify-center">
              <img :src="item.icon" class="h-30" />
            </div>
            <div class="mt-2 rounded-xl">
              <div class="h-36 px-4 pt-4">
                {{ item.description }}
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Navbar from "@/components/Common/Navbar/Plain";
import slugsConfig from "@/common/slugs.config";

export default {
  name: "ToolsCatalog",
  components: { Navbar },
  data() {
    return {
      dataFetched: true,
      data: null,
      slugsConfig,
    };
  },
  created() {
    const vue = this;
    ApiService.get(apiResource.tools.catalog)
      .then(({ data }) => {
        vue.dataFetched = true;
        vue.data = data.data;
      })
      .catch(() => {});
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="postcss"></style>
